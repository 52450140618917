import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import { modelMap, EntityDropDownSettings, STATIC_ENTITES } from "../config/config";
import { ENTITY_DROPDOWN_SETTINGS } from "../config/lang";

import { call } from "../util/action";
import { setDefaultTemplate } from "../util/redux/actions";
import { saveState } from "../util/redux/localStorage";
import { getStore } from "../util/redux/store";
import { maptoSelect2Objects, translate } from "../util/util";

class EntityDropdown extends Component {
  static defaultProps = {
    asynch: false,
    useFullOptionObject: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      list: [],
      searchTerm: "",
      keyTrick: 1, // used to trigger new loadOptions,
    };

    this.customStyles = {
      menu: () => ({
        boxShadow: "none",
        position: "absolute",
        zIndex: 99,
        top: "100%",
        width: "100%",
      }),
      menuList: () => ({
        background: "#fff",
        maxHeight: "260px",
        overflow: "auto",
        border: "1px solid #eee",
      }),
    };
  }

  componentDidMount() {
    if (!this.props.asynch) {
      this.fetchData();
    }
  }

  componentDidUpdate(prevProps) {
    const { county, moduleFilter, updatedList } = this.props;
    if (prevProps.county !== county || prevProps.moduleFilter !== moduleFilter) {
      this.setState(prevState => ({
        keyTrick: prevState.keyTrick + 1,
      }));
    }

    if (prevProps.updatedList !== updatedList) {
      this.setState({ list: updatedList });
    }
  }

  fetchData = () => {
    let { entity } = this.props;

    if (["task_negative", "task_status", "task_deadline"].includes(entity)) {
      this.setState({ list: maptoSelect2Objects(STATIC_ENTITES[entity], entity) });
      return;
    }

    let url = modelMap[entity];
    if ((this.props.county && entity === "point") || (this.props.county && entity === "points")) {
      url = "points?locality__county=" + this.props.county;
    }
    if (entity == "brands") {
      url = entity + "/?page_size=1000"; //max number of brands without pages
    }
    if (entity == "language") {
      url = "users/";
    }
    //Custom Select Data for Date Range on Visit section
    if (entity == "date_range") {
      const dateAgoList = [
        { id: 0, label: "Today", value: this.props.setDateAgo("today"), name: "today" },
        { id: 1, label: "Yesterday", value: this.props.setDateAgo("yesterday"), name: "Yesterday" },
        { id: 2, label: "Current week", value: this.props.setDateAgo("current_week"), name: "Current week" },
        { id: 3, label: "Last week", value: this.props.setDateAgo("last_week"), name: "Last week" },
        { id: 4, label: "Current month", value: this.props.setDateAgo("current_month"), name: "Current month" },
        { id: 5, label: "Last month", value: this.props.setDateAgo("last_month"), name: "Last month" },
        { id: 6, label: "Last half a year", value: this.props.setDateAgo("last_half_a_year"), name: "Last half a year" },
      ];
      this.setState({ list: dateAgoList });
    } else {
      call({ method: "GET", url }, response => {
        if (
          entity == "filters" ||
          entity == "users" ||
          entity == "task" ||
          entity == "visit" ||
          entity == "point" ||
          entity == "points" ||
          entity == "brand" ||
          entity == "brands" ||
          entity == "product" ||
          entity == "products" ||
          entity == "subcategory" ||
          entity == "subcategories"
        ) {
          response = response.results;
        }

        if (entity === "language") {
          //Set languages as default
          response = [
            { id: 1, code: "uk", name: "Українська" },
            { id: 2, code: "en", name: "English" },
          ];
        }
        response = maptoSelect2Objects(response, entity);
        this.setState({ list: response });
        if (entity === "template") {
          let defaultOption = response?.find(item => item.is_default);
          getStore().dispatch(setDefaultTemplate(defaultOption));
          saveState({
            ...getStore().getState(),
            defaultTemplate: defaultOption?.id,
          });
        }
      });
    }
  };

  formatValue = value => {
    if (this.props.isMulti) {
      return value?.map(i => {
        if (i.name) {
          return { ...i, value: i.id, label: i.name };
        } else {
          return { ...i, value: i.id, label: i.label };
        }
      });
    }

    let defaultOption = this.state.list?.find(item => item.is_default);
    let option = this.state.list.find(o => {
      return o.id === value;
    });

    if (this.props.entity == "language" && value) {
      option = this.state.list.find(o => {
        return o.code === value;
      });
    }

    if (this.props.entity == "date_range" && value) {
       option = this.state.list.find(o => {
         return o.value === value;
       });
    }

    if (this.props.useFullOptionObject) {
      option = value;
    }
    if (this.props.useDefaultValue && value === "") {
      option = defaultOption;
    }

    if (option) {
      let label = option.name ? option.name : "";
      if (this.props.entity == "merchandiser") label = option.first_name + " " + option.last_name;
      if (this.props.entity == "merchandisers") label = option.first_name + " " + option.last_name;
      if (this.props.entity == "supervisor") label = option.first_name + " " + option.last_name;
      if (this.props.entity == "created_by") label = option.first_name + " " + option.last_name;
      if (this.props.entity == "point") label = option.name + ", " + option.locality.name;
      if (this.props.entity == "points") label = option.name + ", " + option.locality.name;
      return { value: option.id, label };
    } else {
      return null;
    }
  };

  asynchLoadOptions = inputValue => {
    const { entity, county, moduleFilter } = this.props;
    return new Promise(resolve => {
      let url = entity + "s/?search=" + inputValue; // s in"s/?search=" - it is just a simple letter "s" was added to entity name, i.e: "product" + "s" = "products" ://
      if (entity == "points") {
        url = entity + "/?search=" + inputValue;
      }
      if (entity == "point" || entity == "points") {
        url += `&locality__county=${county || ""}&network=${moduleFilter || ""}`;
      }
      if (entity == "products") {
        url = entity + "/?search=" + inputValue;
      }
      call({ method: "GET", url }, r => {
        let list = r.results;
        if (entity == "template") {
          list = r;
        }

        list = maptoSelect2Objects(list, this.props.entity);

        this.setState({
          list,
        });

        resolve(list);
      });
    });
  };

  onAsynchInputChange = newValue => {
    // this.asynchLoadOptions(newValue);
    // this.setState({ searchTerm: newValue });

    return newValue;
  };

  render() {
    const { keyTrick } = this.state;
    let className = this.props.className ? this.props.className : "";

    return (
      <div className={`react-dropdown-container ${className}`}>
        {!!this.props.asynch ? (
          <AsyncSelect
            key={keyTrick}
            cacheOptions
            // defaultOptions={list}
            defaultOptions
            action={["clear"]}
            isMulti={this.props.isMulti || false}
            value={this.formatValue(this.props.value) || null}
            loadOptions={this.asynchLoadOptions}
            onInputChange={this.onAsynchInputChange}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : translate(
                    ENTITY_DROPDOWN_SETTINGS,
                    EntityDropDownSettings[this.props.entity]?.selectTitle,
                    this.props.me?.language ? this.props.me.language : "en"
                  )
            }
            onChange={this.props.onChange}
            isClearable={this.props.isClearable ?? true}
            isDisabled={this.props.isDisabled}
            styles={this.customStyles}
            className="react-select-custom-container"
            classNamePrefix="custom-react-select"
          />
        ) : (
          <Select
            options={this.state.list}
            isMulti={this.props.isMulti}
            value={this.formatValue(this.props.value) || []}
            // placeholder={this.props.placeholder ? this.props.placeholder : EntityDropDownSettings[this.props.entity]?.selectTitle}
            placeholder={
              this.props.placeholder
                ? this.props.placeholder
                : translate(
                    ENTITY_DROPDOWN_SETTINGS,
                    EntityDropDownSettings[this.props.entity]?.selectTitle,
                    this.props.me?.language ? this.props.me.language : "en"
                  )
            }
            onChange={this.props.onChange}
            isClearable={this.props.isClearable ?? true}
            isDisabled={this.props.isDisabled}
            styles={this.customStyles}
            className="react-select-custom-container"
            classNamePrefix="custom-react-select"
          />
        )}
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(EntityDropdown));
