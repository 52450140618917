import React, { useEffect } from "react";
import moment from "moment";
import { TableHeaderColumn, BootstrapTable } from "react-bootstrap-table";

import Loading from "../../../component/Loading";
import { dataTableConfig } from "../../../config/config";
import { VISIT_PAGE } from "../../../config/lang";

import { call } from "../../../util/action";
import { getStore } from "../../../util/redux/store";
import { translate } from "../../../util/util";

const me = getStore().getState().me;
function Tasks({ data }) {
  const [state, setState] = React.useState({ visits: "", loading: false });
  useEffect(() => {
    setState({ loading: true });
    let url = `visits/${data.id}/tasks_in_visit/`;

    call({ method: "GET", url }, response => {
      setState({ visits: response, loading: false });
    });
  }, [data]);
console.log(data);
  const getType = item => {
    if (!item) return;
    return `<span class="task-type-name">${item}</span>`;
  };

  const getDeadline = item => {
    if (!item) return;

    let newDate = new Date(item);
    const date = moment(newDate).format("DD/MM/yyyy");
    return `<span class="visit-task-date">${date}</span>`;
  };

  const getTaskStatus = item => {
    if (!item) return;
    const str = item.toLowerCase();
    const result = str.replace(/ /g, "-");
    return `<span class="visit-task-status visit-${result}">${item}</span>`;
  };

  const getTaskRowStatusClass = item => {
    if (!item.status) return;
    const str = item.status.toLowerCase();
    const result = str.replace(/ /g, "-");
    return result;
  };

  return (
    <>
      <div className="visit-task-tab-wrapper">
        {state.visits ? (
          <BootstrapTable
            data={state.visits}
            version="4"
            striped
            hover
            bordered={false}
            pagination={false}
            search={false}
            multiColumnSearch={true}
            options={{ ...dataTableConfig }}
            className="themed-main-table-container"
            trClassName={row => `visit-task-tab-item ${getTaskRowStatusClass(row)}`}
            tableHeaderClass="primary">
            <TableHeaderColumn dataFormat={getType} dataField="template_name" isKey={true} width="180px">
              {translate(VISIT_PAGE, "task_tab_type_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="title" width="280px">
              {translate(VISIT_PAGE, "task_tab_task_name_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="description" width="280px">
              {translate(VISIT_PAGE, "task_tab_info_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getDeadline} dataField="end" width="100px">
              {translate(VISIT_PAGE, "task_tab_deadline_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataField="image_count" width="146px">
              {translate(VISIT_PAGE, "task_tab_count_photo_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={getTaskStatus} dataField="status" width="100px">
              {translate(VISIT_PAGE, "task_tab_status_label", me.language)}
            </TableHeaderColumn>
          </BootstrapTable>
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default Tasks;
