
export const API_URL_LOGIN = process.env.API_LOGIN;
export const API_URL_PREFIX = process.env.API_PREFIX;
export const PHOTO_REVIEW_IMAGE_URL_BASE = process.env.PHOTO_REVIEW_IMAGE_URL_BASE;

export const modelMap = {
  merchandiser: "merchandisers",
  merchandisers: "merchandisers",
  supervisor: "supervisors/",
  created_by: "supervisors/",
  filters: "filters/",

  route: "routes",
  task: "tasks",

  point: "points",
  points: "points",
  visit: "visits",
  network: "networks/",
  point__network: "networks/",
  networks: "networks/",
  point__networks: "networks/",
  county: "counties/",
  locality: "localities",
  language: "language",
  brand: "brands",
  brands: "brands",
  product: "products",
  products: "products",
  subcategory: "subcategories",
  subcategories: "subcategories",
  tag: "tags",
  tags: "tags",
  component: "components",
  company: "companies",
  template: "templates/",
};

export const STATIC_ENTITES = {
  task_negative: [
    { id: true, name: "ТАК" },
    { id: false, name: "НІ" },
  ],

  task_deadline: [
    { id: true, name: "ТАК" },
    { id: false, name: "НІ" },
  ],

  task_status: [
    { id: "no_answers", name: "Не виконані" },
    { id: "on_review", name: "На затверджені" },
    { id: "done", name: "Виконані" },
  ],
};

export const EntityDropDownSettings = {
  filters: { action: "list", title: c => c.label, selectTitle: "custom_filter" },
  merchandiser: { action: "list", title: c => c.first_name + " " + c.last_name, selectTitle: "merchandiser" },
  merchandisers: { action: "list", title: c => c.first_name + " " + c.last_name, selectTitle: "merchandisers" },
  supervisor: { action: "list", title: c => c.first_name + " " + c.last_name, selectTitle: "supervisor" },

  point: { action: "list", title: c => c.name + " " + c.address, selectTitle: "point" },
  points: { action: "list", title: c => c.name + " " + c.address, selectTitle: "points" },
  network: { action: "list", title: c => c.name, selectTitle: "network" },
  point__network: { action: "list", title: c => c.name, selectTitle: "network" },
  point__networks: { action: "list", title: c => c.name, selectTitle: "network" },
  networks: { action: "list", title: c => c.name, selectTitle: "networks" },
  created_by: { action: "list", title: c => c.name, selectTitle: "created_by" },

  county: { action: "list", title: c => c.name, selectTitle: "region" },
  locality: { action: "list", title: c => c.name, selectTitle: "city" },

  brand: { action: "list", title: c => c.name, selectTitle: "brand" },
  brands: { action: "list", title: c => c.name, selectTitle: "brands" },

  product: { action: "list", title: c => c.name, selectTitle: "product" },
  products: { action: "list", title: c => c.name, selectTitle: "products" },

  subcategory: { action: "list", title: c => c.name, selectTitle: "category" },
  subcategories: { action: "list", title: c => c.name, selectTitle: "categories" },

  tag: { action: "list", title: c => c.name, selectTitle: "tag" },
  tags: { action: "list", title: c => c.name, selectTitle: "tags" },

  component: { action: "list", title: c => c.name, selectTitle: "component" },
  template: { action: "list", title: c => c.name, selectTitle: "template" },

  task_status: { action: "list", title: c => c.name, selectTitle: "task_status" },
  task_negative: { action: "list", title: c => c.name, selectTitle: "task_negative" },
  task_deadline: { action: "list", title: c => c.name, selectTitle: "task_deadline" },
  date_range: { action: "list", title: c => c.name, selectTitle: "date_range" },
};

export const dataTableConfig = {
  sizePerPage: 50,
  page: 1,
  totalDataSize: 0,
  sizePerPageList: [50, 100, 1000],
  pageStartIndex: 1,
  paginationSize: 3,
  prePage: "Previous",
  nextPage: "Next",
  firstPage: "First",
  lastPage: "Last",
  paging: false,
};
