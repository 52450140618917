import React, { Component } from "react";
import Logo from "/assets/image/logo-light.svg";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import { LangUpdateContext } from "./LangContext";
import { SIDE_NAV } from "../config/lang";

import { logout } from "../util/redux/actions";

const MENU = [
  { name: "photo_report", path: "/photo-review", customIcon: "fa-camera", role: "all" },
  { name: "all_reports", path: "/bi-report", customIcon: "fa-bar-chart", role: "all" },
  { name: "merchandiser", path: "/merchandiser", customIcon: "fa-users", role: "all" },
  { name: "tasks", path: "/task", customIcon: "fa-tasks", role: "all" },
  { name: "answers", path: "/task-review", customIcon: "fa-list", role: "all" },
  { name: "task_uploading", path: "/tasks-import", customIcon: "fa-download", role: "supervisor_manager" },
  // { name: "КАМ-Форми", path: "/k-form", customIcon: "fa-table" },
  { name: "points", path: "/point", customIcon: "fa-map-marker", role: "supervisor_manager" },
  { name: "visit", path: "/visit", customIcon: "fa-map-marker", role: "all" },
  { name: "documents", path: "/document", customIcon: "fa-file", role: "all" },
];

class Aside extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hide: localStorage.getItem("hiddenAside") === "1" || false,
    };

    this.aside = null;
  }
  static contextType = LangUpdateContext;
  componentDidMount() {
    window.addEventListener("resize", this.findHeight);
    this.findHeight();

    if (this.props.location.pathname === "/k-form") {
      this.setState({ hide: true });
    }
    if (this.props.location.pathname === "/photo-review") {
      this.setState({ hide: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.findHeight);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.sidebar !== this.props.sidebar) {
      this.setState({ hide: this.props.sidebar });
    }
    if (prevProps.location.pathname !== this.props.location.pathname && this.props.location.pathname === "/photo-review") {
      this.setState({ hide: this.props.location.pathname });
    }
  }

  findHeight = () => {
    this.setState({ windowH: window.innerHeight });
  };

  hide = () => {
    const { hide } = this.state;
    this.setState({ hide: !hide }, () => {
      localStorage.setItem("hiddenAside", hide ? "0" : "1");
      this.aside.style.display = "none";
      let elem = document.getElementsByClassName("modal-backdrop show")[0];
      if (elem) {
        document.body.className = document.body.className.replace("modal-open", "");
        elem.parentNode.removeChild(elem);
      }
    });
  };

  logout = () => this.props.dispatch(logout());

  customItem = text => {
    const { me } = this.props;
    return SIDE_NAV[text][me.language] ? SIDE_NAV[text][me.language] : text;
  };

  menuItem = item => {
    const { me } = this.props;
    if (item.role !== me.role && item.role !== "all") return;
    let { name, path, customIcon } = item;
    name = SIDE_NAV[name][me.language] ? SIDE_NAV[name][me.language] : SIDE_NAV[name]["uk"];
    if (item.hr) return <hr className="divorserLine" key={item.key} />;

    if (item.type == "group") {
      return (
        <li key={`m-i-${name}`}>
          <span className="nav-group nav-text">{name}</span>
        </li>
      );
    }

    const isCurrent = this.props.location.pathname === path;
    let hide = null;
    if (window.innerWidth <= 922) hide = this.hide;
    const onClickMenuFn = () => {
      if (typeof hide === "function") {
        hide();
      } else if (this.state.hide) {
        this.setState({ hide: false });
      }
    };

    return (
      <li key={`m-i-${name}`} className={isCurrent ? "active" : ""}>
        <Link to={path} onClick={onClickMenuFn}>
          <span className="nav-icon">
            <div className={this.state.hide ? "folded_icon_wrapper" : ""}>
              <span className="faIcon">
                <i className={"fa " + customIcon} />
              </span>
            </div>
          </span>
          <span className="nav-text">{name}</span>
        </Link>
      </li>
    );
  };

  render() {
    const { hide, windowH } = this.state;
    const { inText, me } = this.props;

    let scrollAsideHeight = {
      height: windowH - 85 + "px",
    };
    if (!me) return null;

    return (
      <div id="aside" ref={el => (this.aside = el)} className={`app-aside modal nav-dropdown ${hide ? "folded" : ""}`} aria-hidden="true">
        <div className="left navside primary">
          <div className="navbar navbar-md navbar-hd box-shadow-z4" onClick={this.hide}>
            <a className="navbar-brand mx-auto text-center">
              <span className="nav-icon d-none d-lg-block">
                <i className={`fa fa-bars primary ${hide ? "" : "hide"}`} />
              </span>
              <span className="hidden-folded m-0" style={{ color: "#000" }}>
                <img src={Logo} className="" />
              </span>
            </a>
          </div>
          <nav className="navAside">
            <ul className="nav">
              {MENU.map(this.menuItem)}
              <hr className="divorserLine" />
              <li>
                <a onClick={this.logout}>
                  <span className="nav-icon">
                    <div className={this.state.hide ? "folded_icon_wrapper" : ""}>
                      <span className="faIcon">
                        <i className="fa fa-sign-out" />
                      </span>
                    </div>
                  </span>
                  <span className="nav-text">{this.customItem("logout")}</span>
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    );
  }
}

Aside = connect(state => ({
  me: state.me,
  sidebar: state.sidebar,
}))(Aside);

export default withRouter(Aside);
