import React, { useEffect } from "react";

import Loading from "../../../component/Loading";

import { call } from "../../../util/action";

function Monitoring({ data }) {
  const [state, setState] = React.useState({ monitoring: "", loading: false });
  useEffect(() => {
    setState({ loading: true });

    let url = `visits/${data.id}/price_monitoring_in_visit/`;

    call({ method: "GET", url }, response => {
      console.log(response);
      setState({ reports: response.results, loading: false });
    });
  }, [data]);

  // console.log(state);

  return (
    <>
      <div className="visit-monitoring-tab-wrapper">{state.monitoring ? "Monitoring" : <Loading loading={state.loading} />}</div>
    </>
  );
}

export default Monitoring;
