import React from "react";
import { TableHeaderColumn } from "react-bootstrap-table";
import { useHistory } from "react-router-dom";

import EntityTable from "../../component/EntityTable";
import { VISIT_PAGE } from "../../config/lang";

import {
  visitPoint,
  timeSpent,
  authorWay,
  supervisorName,
  approvedBy,
  openVisitRow,
  approveVisitStatus,
  visitItemStatus,
  visitItemProgress,
} from "../../util/formatter";
import { getStore } from "../../util/redux/store";
import { translate } from "../../util/util";

const me = getStore().getState().me;
const List = ()=> {
 const history = useHistory();

  const onRowClick = (row)=> {
    openVisitRow(row.id, history);
  }

  const rowClassNameFormat = function() {
    return "active-row";
  };

    return (
      <div className="container-fluid visit-page">
        {/* <div className="box px-4 py-1">
          <h5 className="mt-3 mb-3">
            <span className="faIcon">
              <i className="fa fa-tasks" />
            </span>
            {translate(VISIT_PAGE, "page_title", me.language)}
          </h5>
        </div> */}

        <div className="box p-4">
          <EntityTable entity="visit" openVisitFn={onRowClick} rowClassName={rowClassNameFormat}>
            {/* <TableHeaderColumn dataField="id" dataFormat={openVisit} isKey width="120px"></TableHeaderColumn> */}
            <TableHeaderColumn dataFormat={approveVisitStatus} width="37px"></TableHeaderColumn>
            <TableHeaderColumn dataFormat={visitPoint} dataField="id" isKey={true} width="280px">
              {translate(VISIT_PAGE, "point", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={supervisorName} width="220px">
              {translate(VISIT_PAGE, "supervisor", me.language)}
            </TableHeaderColumn>
            {/* перевірити чому немає аватарів заглушок */}
            <TableHeaderColumn dataFormat={approvedBy} width="220px">
              {translate(VISIT_PAGE, "approved_by", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={authorWay} width="220px">
              {translate(VISIT_PAGE, "author_way", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={timeSpent} width="120px">
              {translate(VISIT_PAGE, "table_timespent", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={visitItemStatus} width="120px">
              {translate(VISIT_PAGE, "status_label", me.language)}
            </TableHeaderColumn>
            <TableHeaderColumn dataFormat={visitItemProgress} width="100px">
              {translate(VISIT_PAGE, "progress_label", me.language)}
            </TableHeaderColumn>
            {/* <TableHeaderColumn dataFormat={visitItemAction} width="100px"></TableHeaderColumn> */}
          </EntityTable>
        </div>
      </div>
    );
}

export default List;
