import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import moment from "moment";
import Alert from "react-s-alert";
import Select from "react-select";

import EntityDropdown from "../../component/EntityDropdown";
import { PageHeader, PageHeaderTitle } from "../../component/general/page-header";
import { NKA_OPT } from "../../config/const";

import { call } from "../../util/action";
import { getStore } from "../../util/redux/store";
import { valueObject } from "../../util/util";

const me = getStore().getState().me;

class View extends Component {
  constructor(props) {
    super(props);

    this.state = {
      items: [],
      totalDataSize: 0,
      sizePerPage: 10,
      pageAmount: 1,
      page: 1,
      components: [],
    };
  }

  componentDidMount() {
    // this.load();

    call({ method: "GET", url: "templates/" }, r => {
      let t = r.find(i => i.id == 1);

      if (t) {
        this.setState({ components: t.components.map(i => i.id) });
      }
    });
  }

  load = () => {
    call({ method: "GET", url: "tasks?template=2&page=" + this.state.page }, r => {
      let pageAmount = Math.ceil(r.count / this.state.sizePerPage);
      this.setState({ items: this.prepareItemsToLoad(r.results), pagination: true, count: r.count, loading: false, pageAmount: pageAmount });
    });
  };

  loadPage = page => {
    this.setState({ page: page }, () => {
      this.load();
    });
  };

  submit = () => {
    let items = this.prepareItemsToSend(this.state.items);

    console.log("items post bulk", items);
    console.log("items post bulk", JSON.stringify(items));

    call({ method: "POST", url: "tasks/", post: items }, r => {
      console.log("response", r);
      Alert.success("Items successfully saved", { position: "bottom-right" });

      // this.setState({items:r});
      // if (r &&  r.id) {
      //     Alert.success('Task successfully saved', {position: 'bottom-right'});
      // } else {
      //     Alert.warning('Error while saving task. Error: '+JSON.stringify(r), {position: 'bottom-right'});
      // }
    });

    // items.map(this.submitOne);
  };

  submitOne = (item, idx) => {
    call({ method: item && item.id ? "PATCH" : "POST", url: item && item.id ? "tasks/" + item.id + "/" : "tasks/", post: item }, r => {
      console.log("item post", item);
      console.log("result", r);

      if (r && r.id) {
        Alert.success("Task successfully saved", { position: "bottom-right" });
      } else {
        Alert.warning("Error while saving task. Error: " + JSON.stringify(r), { position: "bottom-right" });
      }
    });
  };

  prepareItemsToLoad = items => {
    let ai = {};

    items.forEach((i, idx) => {
      ai = JSON.parse(i.additional_info);

      i.kam = ai.kam ? ai.kam : "";
      i.channel = ai.channel ? ai.channel : "";
      i.brand = ai.brand ? ai.brand : "";
      i.product = ai.product ? ai.product : "";
      i.promo = ai.promo ? ai.promo : "";
      i.price = ai.price ? ai.price : "";
      i.discount = ai.discount ? ai.discount : "";
      i.info = ai.info ? ai.info : "";

      items[idx] = { ...i };
    });

    return items;
  };

  prepareItemsToSend = items => {
    let item = {};
    let additional_info = null;

    items.forEach((i, idx) => {
      additional_info = {
        kam: i.kam,
        channel: i.channel,
        brand: i.brand,
        product: i.product,
        promo: i.promo,
        price: i.price,
        discount: i.discount,
        info: i.info,
      };

      additional_info = JSON.stringify(additional_info);

      item = {
        network: i.network && i.network.id ? i.network.id : null,

        title: "БРЕНД: " + i.brand + ", ПРОДУКТ ЛІНІЙКИ: " + i.product + ", ТИП ПРОМО: " + i.promo + ", ЗНИЖКА %:" + i.price + ", ЦІНА:" + i.discount,

        template: 1,
        created_by: me.id,
        company: me.company && me.company.id ? me.company.id : null,

        start: moment(i.start).format("yyyy-MM-DDT00:00"),
        end: moment(i.end).format("yyyy-MM-DDT00:00"),

        additional_info: additional_info,

        components: this.state.components,
      };

      if (i.id) {
        item.id = i.id;
      }

      items[idx] = item;
    });

    return items;
  };

  deleteRow = idx => {
    let items = this.state.items;
    delete items[idx];
    this.setState({ items: items });
  };

  addRow = i => {
    let items = this.state.items;

    items.push({
      network: null,
      nka: null,
      kam: "",

      start: new Date(),
      end: new Date(),

      brand: "",
      product: "",
      promo: "",
      discount: "",
      price: "",
      info: "",
    });

    this.setState({ items: items });
  };

  onChange = (property, value, idx) => {
    let items = this.state.items;
    items[idx][property] = value;
    this.setState({ items: items });
  };

  renderRow = (item, idx) => {
    return (
      <tr>
        <td className="KForm-firstRow td1">
          <i className="KFormDelete fa fa-minus-square" onClick={() => this.deleteRow(idx)} />
          <EntityDropdown entity="network" value={item.network && item.network.id} onChange={o => this.onChange("network", o, idx)} placeholder="Select" />
        </td>

        <td className="td2">
          <Select options={NKA_OPT} value={valueObject(item.channel, NKA_OPT)} onChange={o => this.onChange("channel", o.value, idx)} placeholder="Select" />
        </td>

        <td className="td3">
          <input type="text" value={item.kam} className="kform-kam" onChange={o => this.onChange("kam", o.target.value, idx)} />
        </td>

        <td className="td4">
          <DatePicker selected={new Date(item.start)} onChange={date => this.onChange("start", date, idx)} className="datePicker kform-date" />
        </td>

        <td className="td5">
          <DatePicker selected={new Date(item.end)} onChange={date => this.onChange("end", date, idx)} className="datePicker kform-date" />
        </td>

        <td className="td6">
          <input type="text" value={item.brand} className="kform-discount" onChange={o => this.onChange("brand", o.target.value, idx)} />
        </td>

        <td className="td7">
          <input type="text" value={item.product} className="kform-discount" onChange={o => this.onChange("product", o.target.value, idx)} />
        </td>

        <td className="td8">
          <input type="text" value={item.promo} onChange={o => this.onChange("promo", o.target.value, idx)} />
        </td>

        <td className="td9">
          <input type="text" value={item.discount} className="kform-discount" onChange={o => this.onChange("discount", o.target.value, idx)} />
        </td>

        <td className="td10">
          <input type="text" value={item.price} className="kform-price" onChange={o => this.onChange("price", o.target.value, idx)} />
        </td>

        <td className="td11">
          <textarea onChange={o => this.onChange("info", o.target.value, idx)}>{item.info}</textarea>
        </td>
      </tr>
    );
  };

  render() {
    const { page } = this.state;

    return (
      <div className="container-fluid">
        <PageHeader>
          <PageHeaderTitle>
            <span className="faIcon">
              <i className="fa fa-table" />
            </span>
            КАМ-Форми
          </PageHeaderTitle>
        </PageHeader>

        <div className="box p-4">
          <table className="table table-hover table-bordered tableKForm">
            <thead className="primary text-center">
              <tr>
                <td rowspan="2">Клієнт</td>
                <td rowspan="2">Канал</td>
                <td rowspan="2">KAM|KAE|RSM</td>
                <td colspan="2">Період</td>
                <td colspan="2">Група продукції</td>
                <td rowspan="2">Тип промо</td>
                <td rowspan="2">Знижка %</td>
                <td rowspan="2">Ціна</td>
                <td rowspan="2">Додаткова інформація</td>
              </tr>
              <tr>
                <td>Старт</td>
                <td>Завершення</td>
                <td>Бренд</td>
                <td>Продукт лінійки</td>
              </tr>
            </thead>
            {this.state.items.map((i, idx) => {
              return this.renderRow(i, idx);
            })}
          </table>

          <div className="col-xs-12">
            <ul className="react-bootstrap-table-page-btns-ul pagination d-inline-block">
              {new Array(this.state.pageAmount).fill(this.state.pageAmount).map((i, idx) => {
                // return <li className={"page-item "+(page==(idx+1)?'active':'')}><a onClick={()=>this.loadPage(idx+1)}  className="page-link">{idx+1}</a></li>
              })}
            </ul>
          </div>

          <div className="row">
            <div className="col-md-12 text-center">
              <div className="btn btn-outline-primary" onClick={e => this.addRow()}>
                {" "}
                <i className="fa fa-plus" /> Додати запис{" "}
              </div>
              <div className="btn btn-primary  ml-3" onClick={e => this.submit()}>
                {" "}
                <i className="fa fa-save" /> Зберегти зміни
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default View;
