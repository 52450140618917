import React, { useEffect } from "react";

import Loading from "../../../component/Loading";

import { call } from "../../../util/action";

function Report({ data }) {
  const [state, setState] = React.useState({ reports: "", loading: false });
  useEffect(() => {
    setState({ loading: true });

    let url = `visits/${data.id}/photos_in_visit/`;

    call({ method: "GET", url }, response => {
      setState({ reports: response.results, loading: false });
    });
  }, [data]);

  console.log(state.reports);

  return (
    <>
      <div className="visit-report-tab-wrapper">
        {state.reports ? (
          state.reports.map(item => (
            <div className="visit-report-item" key={item.id}>
              <div className="photo-item-wrapper">
                <img src={item.url_photo} alt="" />
              </div>
              <p className="photo-report-item-content">
                {item.visit.point.address} {item.visit.point.name}
              </p>
            </div>
          ))
        ) : (
          <Loading loading={state.loading} />
        )}
      </div>
    </>
  );
}

export default Report;
