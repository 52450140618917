import React, { Component } from "react";
import { DatePicker } from "/src/component/general/DatePicker";
import { BootstrapTable } from "react-bootstrap-table";
// import BootstrapTableNext from "react-bootstrap-table-next";
// import paginationFactory from "react-bootstrap-table2-paginator";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";

import EntityDropdown from "./EntityDropdown";
import Loading from "./Loading";
import Pagination from "./Pagination";
import PointFilter from "./PointFilter";
import { dataTableConfig } from "../config/config";
import { ENTITY_TABLE } from "../config/lang";

import { call } from "../util/action";
import { translate } from "../util/util";
import { filterUrl } from "../util/util";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";

// import Input from './Input';
class EntityTable extends Component {
  constructor(props) {
    super(props);

    this.defaultCreatedFromDate = new Date(new Date().setDate(new Date().getDate() - 14));

    const start = new Date();
    this.yearAgo = start.setFullYear(start.getFullYear() - 1);

    this.state = {
      data: [],
      count: 0,
      loading: false,
      valid: false,

      pagination: {
        state: false,
        count: 0,
        sizePerPage: 25,
        pageAmount: 1,
        page: 1,
      },

      filter: {
        merchandiser: { search: "" },

        route: { user: "", day_of_the_week: "" },

        task: {
          start: null,
          end: null,
          createdDateFrom: this.defaultCreatedFromDate,
          createdDateTo: null,
          user: "",
          no_answers: "",
          task_status: "",
          task_deadline: "",
          task_negative: "",
          network: "",
          point__network: "",
          created_by: "",
          point: "",
          point__responsible_supervisor: "",
          template: "",
          point__locality__county: this.props.me?.county?.id,
          searchText: "",
        },

        visit: { user: "", point__network: "", points: [], county: "", start: this.yearAgo, end: new Date(), date_range: "" },
        point: { network: "", county: "", locality: "" },
      },
    };
  }

  componentDidMount() {
    this.load();
  }

  load = () => {
    this.setState({ loading: true });

    if (this.props.type && this.props.type === "data") {
      this.setState({ data: this.props.data, loading: false, count: this.props.data.length });
      return;
    }

    let { entity } = this.props;
    let { pagination } = this.state;

    let url = entity + "s/";
    let urlFilter = filterUrl(entity, this.state.filter[entity]);

    if (entity === "task" || entity === "visit" || entity === "point" || entity === "task-review") {
      url += "?page=" + pagination.page;
    }

    if (urlFilter) url += urlFilter;

    call({ method: "GET", url }, response => {
      if (entity === "task" || entity === "visit" || entity === "point" || entity === "task-review") {
        const newPagination = {
          state: true,
          pageAmount: Math.ceil(response.count / pagination.sizePerPage),
          count: response.count,
          page: pagination.page,
          sizePerPage: pagination.sizePerPage,
        };

        this.setState({ data: response.results, count: response.count, loading: false, pagination: newPagination });
      } else if (response.length > 0) {
        this.setState({ data: response, count: response.count, loading: false });
      } else {
        this.setState({ data: [], count: 0, loading: false });
      }
    });
  };

  setDateAgo = (option = "today") => {
    const today = new Date();
    let date;

    switch (option) {
      case "today": {
        date = today;
        break;
      }

      case "yesterday": {
        const yesterday = new Date(today);
        yesterday.setDate(today.getDate() - 1);
        date = yesterday;
        break;
      }

      case "current_week": {
        const currentWeekStart = new Date(today);
        currentWeekStart.setDate(today.getDate() - today.getDay());
        date = currentWeekStart;
        break;
      }

      case "last_week": {
        const lastWeekStart = new Date(today);
        lastWeekStart.setDate(today.getDate() - today.getDay() - 7);
        date = lastWeekStart;
        break;
      }

      case "current_month": {
        const currentMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
        date = currentMonthStart;
        break;
      }

      case "last_month": {
        const lastMonthStart = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        date = lastMonthStart;
        break;
      }

      case "last_half_a_year": {
        const lastHalfYear = new Date(today);
        lastHalfYear.setMonth(today.getMonth() - 6);
        date = lastHalfYear;
        break;
      }

      default:
        throw new Error("Invalid option");
    }

    return date.getTime();
  };

  changeFilter = (entity, filterEntity, item) => {
    let filter = this.state.filter;
    const dateAndTextFilters = ["start", "end", "createdDateFrom", "createdDateTo", "searchText"];

    switch (true) {
      case entity === "merchandiser" && filterEntity === "search":
        filter[entity][filterEntity] = item.target.value;
        break;

      case entity === "task" && dateAndTextFilters.includes(filterEntity):
        filter[entity][filterEntity] = item;
        break;

      case entity === "task" && filterEntity === "task_deadline":
        filter[entity][filterEntity] = item.target?.checked;
        break;

      case entity === "task" && filterEntity === "task_negative":
        filter[entity][filterEntity] = item.target?.checked;
        break;

      case entity === "visit" && filterEntity === "points":
        filter[entity][filterEntity] = item;
        break;

      case (entity === "visit" && filterEntity === "start") || (entity === "visit" && filterEntity === "end"):
        filter[entity][filterEntity] = item;
        break;

      case entity === "visit" && filterEntity === "date_range":
        if (item) {
          filter[entity][filterEntity] = item.value;
          filter[entity]["start"] = item.value;
        } else {
          filter[entity][filterEntity] = "";
        }
        break;

      default:
        filter[entity][filterEntity] = item?.id;
        break;
    }

    this.setState({ filter });
    // this.setState({ filter }, () => {
    //   this.setDateAgo();
    // });
  };

  submit = () => this.updatePaging(1);

  clearFilter = () => {
    let filter = this.state.filter;
    Object.keys(filter[this.props.entity])?.map(item => {
      filter[this.props.entity][item] = null;

      if (item === "createdDateFrom") {
        filter[this.props.entity][item] = this.defaultCreatedFromDate;
      }

      if (item === "task_deadline" || item === "task_negative") {
        filter[this.props.entity][item] = false;
      }
    });

    this.setState({ filter }, () => {
      this.updatePaging(1);
    });
  };

  // PAGINATION
  updatePaging = (page, cb) => {
    let { pagination } = this.state;
    pagination.page = page;

    this.setState({ pagination }, () => {
      this.load();
      if (cb) cb();
    });
  };

  render() {
    if (!this.props.me) return null;

    const { data, loading, filter } = this.state;
    const { entity, me, openVisitFn, rowClassName } = this.props;
    let isNewBtn = ["merchandiser", "task"].indexOf(this.props.entity) != -1;

    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-9 text-left">
            <div className="filter-bar mb-2 boxForm">
              {entity === "merchandiser" && (
                <React.Fragment>
                  <div>
                    <input
                      type="text"
                      className="form-control searchInput"
                      placeholder={translate(ENTITY_TABLE, "search_placeholder", me.language)}
                      value={filter.merchandiser.search}
                      onChange={e => {
                        this.changeFilter("merchandiser", "search", e);
                      }}
                    />
                  </div>
                </React.Fragment>
              )}

              {entity === "route" && (
                <React.Fragment>
                  <EntityDropdown
                    entity="merchandiser"
                    value={filter.route.user}
                    onChange={o => {
                      this.changeFilter("route", "user", o);
                    }}
                  />
                </React.Fragment>
              )}

              {entity === "task" && (
                <React.Fragment>
                  <div className="filter-datepicker">
                    <DatePicker
                      selected={filter.task.start}
                      className="datePicker"
                      onChange={date => this.changeFilter("task", "start", date)}
                      placeholderText={translate(ENTITY_TABLE, "start_date", me.language)}
                      isClearable={true}
                    />
                    {/*<DatePicker*/}
                    {/*  selected={filter.task.end}*/}
                    {/*  className="datePicker"*/}
                    {/*  onChange={date => this.changeFilter("task", "end", date)}*/}
                    {/*  placeholderText={translate(ENTITY_TABLE, "deadline_date", me.language)}*/}
                    {/*  isClearable={true}*/}
                    {/*/>*/}

                    <DatePicker
                      selected={filter.task.createdDateFrom}
                      className="datePicker"
                      onChange={date => this.changeFilter("task", "createdDateFrom", date)}
                      placeholderText={translate(ENTITY_TABLE, "creation_date_from", me.language)}
                      isClearable={true}
                    />
                    <DatePicker
                      selected={filter.task.createdDateTo}
                      className="datePicker"
                      onChange={date => this.changeFilter("task", "createdDateTo", date)}
                      placeholderText={translate(ENTITY_TABLE, "creation_date_to", me.language)}
                      isClearable={true}
                    />
                  </div>
                  {me.role === "supervisor_manager" ? (
                    <EntityDropdown
                      entity="supervisor"
                      value={filter.task.point__responsible_supervisor}
                      onChange={o => {
                        this.changeFilter("task", "point__responsible_supervisor", o);
                      }}
                    />
                  ) : null}

                  <EntityDropdown
                    entity="point__network"
                    value={filter.task.point__network}
                    onChange={o => {
                      this.changeFilter("task", "point__network", o);
                    }}
                  />
                  <EntityDropdown
                    entity="template"
                    value={filter.task.template}
                    // asynch={true}
                    onChange={o => {
                      this.changeFilter("task", "template", o);
                    }}
                  />
                  <EntityDropdown
                    entity="task_status"
                    value={filter.task.task_status}
                    onChange={o => {
                      this.changeFilter("task", "task_status", o);
                    }}
                  />
                  <EntityDropdown
                    entity="created_by"
                    value={filter.task.created_by}
                    onChange={o => {
                      this.changeFilter("task", "created_by", o);
                    }}
                  />
                  <div className="task-form-group">
                    <label>
                      <input
                        type="checkbox"
                        name="task_deadline"
                        className="form-filter-checkbox"
                        checked={filter.task.task_deadline}
                        value={filter.task.task_deadline}
                        onChange={e => {
                          this.changeFilter("task", "task_deadline", e);
                        }}
                      />
                      {translate(ENTITY_TABLE, "filter_overdue_tasks", me.language)}
                    </label>
                    <label>
                      <input
                        type="checkbox"
                        name="task_negative"
                        className="form-filter-checkbox"
                        checked={filter.task.task_negative}
                        value={filter.task.task_negative}
                        onChange={e => {
                          this.changeFilter("task", "task_negative", e);
                        }}
                      />
                      {translate(ENTITY_TABLE, "filter_tasks_with_negative_responses", me.language)}
                    </label>
                  </div>

                  <PointFilter
                    value={filter.task.point}
                    moduleFilter={filter.task?.point__network}
                    onChange={option => {
                      this.changeFilter("task", "point", option);
                    }}
                    onChangeRegion={option => {
                      this.changeFilter("task", "point__locality__county", option);
                    }}
                  />

                  <input
                    type="text"
                    className="form-control searchInput searchInputPhoto mr-1"
                    placeholder={translate(ENTITY_TABLE, "search_placeholder", me.language)}
                    value={filter.task.searchText}
                    onChange={e => {
                      this.changeFilter("task", "searchText", e.target.value);
                    }}
                  />

                  {/* {( filter.task.supervisor || filter.task.network || filter.task.point ||
                                        filter.task.no_answers || filter.task.template || filter.task.status
                                    ) && <span className="clearFilter" onClick={() => this.clearFilter()}><i className="fa fa-times" /></span>} */}
                  <div className="btn btn-outline-primary" onClick={this.clearFilter}>
                    {translate(ENTITY_TABLE, "filter_reset_btn", me.language)}
                  </div>
                </React.Fragment>
              )}

              {entity === "visit" && (
                <React.Fragment>
                  <div className="filter-datepicker">
                    <DatePicker
                      selected={new Date(filter.visit.start)}
                      className="datePicker mr-2"
                      onChange={date => this.changeFilter("visit", "start", date)}
                    />
                    <DatePicker selected={new Date(filter.visit.end)} className="datePicker mr-2" onChange={date => this.changeFilter("visit", "end", date)} />
                  </div>
                  <EntityDropdown
                    className="date-range"
                    setDateAgo={this.setDateAgo}
                    entity="date_range"
                    value={filter.visit.date_range}
                    onChange={o => {
                      this.changeFilter("visit", "date_range", o);
                    }}
                  />
                  <EntityDropdown
                    entity="merchandiser"
                    value={filter.visit.user}
                    onChange={o => {
                      this.changeFilter("visit", "user", o);
                    }}
                  />

                  <EntityDropdown
                    entity="network"
                    value={filter.visit.point__network}
                    onChange={o => {
                      this.changeFilter("visit", "point__network", o);
                    }}
                  />

                  <PointFilter
                    saveValue={filter.visit.county}
                    value={filter.visit.points}
                    onChange={o => {
                      this.changeFilter("visit", "points", o);
                    }}
                    onChangeRegion={o => {
                      this.changeFilter("visit", "county", o);
                    }}
                    className="react-dropdown-container--multiselect"
                    isMulti
                    moduleFilter={filter.visit.point__network}
                  />

                  {/* <EntityDropdown
                    entity="point"
                    value={filter.visit.point}
                    asynch={true}
                    onChange={o => {
                      this.changeFilter("visit", "point", o);
                    }}
                  /> */}
                </React.Fragment>
              )}

              {entity === "point" && (
                <React.Fragment>
                  <EntityDropdown
                    entity="network"
                    value={filter.point.network}
                    onChange={o => {
                      this.changeFilter("point", "network", o);
                    }}
                  />
                  <EntityDropdown
                    entity="county"
                    value={filter.point.county}
                    onChange={o => {
                      this.changeFilter("point", "county", o);
                    }}
                  />
                  <EntityDropdown
                    entity="locality"
                    value={filter.point.locality}
                    onChange={o => {
                      this.changeFilter("point", "locality", o);
                    }}
                  />

                  {(filter.point.network || filter.point.county || filter.point.locality) && (
                    <span className="clearFilter" onClick={() => this.clearFilter()}>
                      <i className="fa fa-times" />
                    </span>
                  )}
                </React.Fragment>
              )}
            </div>
            {entity === "visit" && (
              <div className="filter-navigations">
                <button className="btn btn-primary mr-1 mb-1" onClick={this.submit}>
                  {translate(ENTITY_TABLE, "apply_btn", me.language)}
                </button>
              </div>
            )}
          </div>
          <div className="col-md-3 text-right">
            {isNewBtn && this.props.entity != "task" && (
              <Link to={"/" + this.props.entity + "/new"} className="btn btn-primary">
                <i className="fa fa-plus"></i> {translate(ENTITY_TABLE, "create_btn", me.language)}
              </Link>
            )}

            {isNewBtn && this.props.entity === "task" && (
              <React.Fragment>
                <Link to={"/task/new/point"} className="btn btn-primary mr-3 mb-1">
                  <i className="fa fa-plus"></i> {translate(ENTITY_TABLE, "to_point_btn", me.language)}
                </Link>
                {/*<Link to={"/task/new/network"} className="btn btn-primary mb-1">
                  {" "}
                  <i className="fa fa-plus"></i> {translate(ENTITY_TABLE, "to_network_btn", me.language)}
                </Link>*/}
              </React.Fragment>
            )}
          </div>
        </div>

        <div className="filtered-task-data">
          <Loading loading={loading} />

          <div className="react-bootstrap-table-counter">
            {translate(ENTITY_TABLE, "records_count", me.language)}: {this.state.count}
          </div>
          {/*<BootstrapTableNext keyField="id" data={data} columns={columns} pagination={paginationFactory(dataTableConfig)} />*/}
          {/*<BootstrapTableNext*/}
          {/*  data={data}*/}
          {/*  version="4"*/}
          {/*  striped*/}
          {/*  hover*/}
          {/*  pagination={false}*/}
          {/*  search={false}*/}
          {/*  multiColumnSearch={true}*/}
          {/*  options={{ ...dataTableConfig }}*/}
          {/*  className="themed-main-table-container"*/}
          {/*  tableHeaderClass="primary">*/}
          {/*  {this.props.children}*/}
          {/*</BootstrapTableNext>*/}

          <BootstrapTable
            data={data}
            version="4"
            striped
            hover
            bordered={false}
            pagination={false}
            search={false}
            multiColumnSearch={true}
            options={{ ...dataTableConfig, onRowClick: openVisitFn }}
            className="themed-main-table-container"
            trClassName={rowClassName ? rowClassName : ""}
            tableHeaderClass="primary">
            {this.props.children}
          </BootstrapTable>

          <div className="react-bootstrap-table-counter">
            {translate(ENTITY_TABLE, "records_count", me.language)}: {this.state.count}
          </div>

          <Pagination
            pagination={this.state.pagination}
            updatePage={page => {
              this.updatePaging(page);
            }}
          />
        </div>
      </div>
    );
  }
}

export default withRouter(connect(state => ({ me: state.me }))(EntityTable));
